
import GenericListing from '../Library/GenericSectionManager/GenericListing.vue'
import { defineComponent } from "vue";
import { blogSectionData } from "./blogSectionData";

export default defineComponent({
    name: "BlogListing",

    components: {
        GenericListing
    },

    setup() {
        return {
            blogSectionData,
        }
    },
})
