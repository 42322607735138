<template>
  <section class="user-management">
    
    <div class="user">

        <v-tabs v-if="userList.length">
         
          <template>
            <v-tab 
              :ripple="{ class: 'accent--text' }">Active</v-tab>

            <v-tab-item
              px-0
              class="main-body">

               <MiniHeader v-if="!modules.default.LFCExclusive" :heading="''" :buttonText="'Add User'" :hasButton="true" @buttonClicked="addItem()" />

              <ol v-if="userList.length > 0" class="user-list">
                <template v-for="item in userList">
                <li class="user-item" v-if="item.UserStatus === 'CONFIRMED' && item.Enabled" :key="item.Username">
                  <div class="user-item__info">

                    <h2 class="user-item__title" v-if="item.Username">{{ item.Username }}</h2>

                  </div>

                  <div class="user-item__actions">
                    <button class="user-item__action" type="button" @click="editItem(item.Username)">
                      <v-icon>edit</v-icon>
                    </button>
                  </div>
                </li>
                </template>
              </ol>

            </v-tab-item>
          </template>

          <template>
            <v-tab
              :ripple="{ class: 'accent--text' }">Unconfirmed</v-tab>

            <v-tab-item
              px-0
              class="main-body">

              <ol v-if="userList.length > 0" class="user-list">
                <template v-for="item in userList">
                <li class="user-item" v-if="(item.UserStatus === 'FORCE_CHANGE_PASSWORD' || item.UserStatus === 'RESET_REQUIRED') && item.Enabled" :key="item.Username">
                  <div class="user-item__info">

                    <h2 class="user-item__title" v-if="item.Username">{{ item.Username }}</h2>

                  </div>

                  <div class="user-item__actions">
                    <button class="user-item__action" type="button" @click="editItem(item.Username)">
                      <v-icon>edit</v-icon>
                    </button>
                  </div>
                </li>
                </template>
              </ol>

            </v-tab-item>
          </template>

          <template>
            <v-tab
              :ripple="{ class: 'accent--text' }">Deactivated</v-tab>

            <v-tab-item
              px-0
              class="main-body">

              <ol v-if="userList.length > 0" class="user-list">
                <template v-for="item in userList">
                <li class="user-item" v-if="!item.Enabled" :key="item.Username">
                  <div class="user-item__info">

                    <h2 class="user-item__title" v-if="item.Username">{{ item.Username }}</h2>

                  </div>

                  <div class="user-item__actions">
                    <button class="user-item__action" type="button" @click="editItem(item.Username)">
                      <v-icon>edit</v-icon>
                    </button>
                  </div>
                </li>
                </template>
              </ol>

            </v-tab-item>
          </template>

        </v-tabs>

      <p v-else>No users to display</p>
    </div>
  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
  name: "AdminUsers",

  components: {
    MiniHeader
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/adminusers`,
    userList: [],
    modules: null,
  }),

  mounted() {
    this.modules = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
    this.fetchVideos();
  },

  methods: {
    fetchVideos() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: this.api,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Users",response);
          this.userList = JSON.parse(response.data.body);
          console.log(this.userList);
        })
        .catch(e => {
          console.error("Error loading admin users:", e);
        });
        this.$store.commit("completeLoading");
      })
    },

    editItem(itemID) {
      this.$store.commit("startLoading");
      this.$router.push({ path: `/admin-users/edit/${itemID}` })
    },

    addItem() {
      this.$store.commit("startLoading");
      this.$router.push({
        path: "/admin-users/add/"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .user-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;


    &__info {
      flex-grow: 1;
    }

    &__date {
      display: block;
      margin: 0 0 8px;
      color: #B8B8B8;
      font-size: 13px;
      font-weight: 300;
    }

    &__title {
      color: #7B6B80;
      font-size: 16px;
      font-weight: 300;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      margin: 0 0 0 20px;
    }
  }
</style>
