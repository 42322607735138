
import { defineComponent, ref } from 'vue';
//@ts-ignore
import IconPicker from "@/components/Library/IconPicker";

export default defineComponent({
    name: "GenericIconInput",

    components: {
        IconPicker
    },

    props: {
        genericFormItemData: {
            type: Object,
            required: true,
        },
    },

    setup(props, { emit }) {
        const input = ref(props.genericFormItemData.value || "");

        function selectIcon(icon: string) {
            input.value = icon
            emit('updated', input.value)
        }

        return {
            input,
            selectIcon
        }
    }
})
