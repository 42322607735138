import { useGenericData } from "../../composables/useGenericData";
import { IGenericSetup } from "../../types/genericData";
import { blogEntryData } from "./blogEntryData";

export const blogSectionData: IGenericSetup = {
    baseURL: "blog",
    text: {
        title: "Blog",
        noItems: "No Blogs were found",
        newItem: "Create New Blog",
        postModalTitle: "New Blog",
        editModalTitle: "Edit Blog",
    },
    api: {
        type: "live-blog", // Our APIs require this property
        // @ts-ignore
        base: process.env.VUE_APP_BLOGAPI,
        create: "/",
        getAll: "/",
        getSingle: "/",
        edit: "/",
        delete: "/",
    },
    create: {
        enabled: true,
        type: "modal",
        schema: [
            {
                name: "Name",
                description: "Displayed name in the CMS",
                type: "text",
                key: "name",
                value: "",
                placeholder: "Name"
            },
            {
                name: "Title",
                description: "Displayed title on the website",
                type: "text",
                key: "title",
                value: "",
                placeholder: "Title",

            },
            {
                name: "Blog Entries",
                type: "none",
                key: "blogEntries",
                value: [],
            },
        ]
    },
    getAll: {
        enabled: true,
        map: {
            name: "name"
        }
    },
    edit: {
        enabled: true,
        type: "navigation",
        schema: [
            {
                name: "Name",
                description: "Displayed name in the CMS",
                type: "text",
                key: "name",
                value: "",
                placeholder: "Name"
            },
            {
                name: "Title",
                description: "Displayed title on the website",
                type: "text",
                key: "title",
                value: "",
                placeholder: "Title",
            },
            {
                name: "Blog Entries",
                type: "none",
                key: "blogEntries",
                value: [],
            },
        ]
    },
    children: [{
        name: "blogEntries",
        value: blogEntryData
    }],
    useGenericData: () => useGenericData(blogSectionData.api)
};
