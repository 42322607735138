<template>
    <v-dialog
        class="dialog"
        v-model="dialog"
        width="1200"
        scrollable
        lazy
        persistent
    >
        <v-card class="card">
            <v-card-title class="slot-title card-title mb-0">{{ title || (itemID ? "Edit Item" : "New Item") }}</v-card-title>
            <v-card-text>
                <generic-form
                    @updated="updateFormData($event)"
                    :genericFormData="genericItemSchema"
                />

            </v-card-text>
            <v-card-actions class="modal-buttons">
                <v-btn
                    accent
                    type="button"
                    color="accent"
                    depressed
                    :class="{ 'no-actions': postLoading || editLoading }"
                    @click="handleSubmit()"
                >
                    {{ postLoading || editLoading ? "Loading..." : "Save" }}
                </v-btn>
                <v-btn
                    text
                    flat
                    @click="cancel()"
                > Cancel </v-btn>
                <p
                    class="text-error"
                    v-if="validationErrors.length"
                >The following properties are required:
                    <span
                        v-for="(valError, index) in validationErrors"
                        :key="valError.key"
                    >
                        {{ index + 1 }}: {{ valError.name }}
                    </span>

                </p>
                <p
                    v-if="postError || editError"
                    class="text-error"
                >{{ postError || editError }}</p>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import GenericForm from "./GenericForm/GenericForm.vue";

export default {
    name: "GenericItemModal",

    components: { GenericForm },

    props: {
        genericItemSchema: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        useGenericData: {
            type: Function,
            required: true,
        },
        dialog: {
            type: Boolean,
            default: false,
        },
        itemID: {
            type: String,
            required: false,
        },
        isChild: {
            type: String,
            required: false,
        }
    },

    data: () => ({
        formData: {},
        validationErrors: [],
        genericCreate: null,
        postError: null,
        postLoading: null,
        genericEdit: null,
        editLoading: null,
        editError: null,
    }),

    created() {
        this.resetFormData()
        const { genericCreate, postError, postLoading, genericEdit, editLoading, editError } = this.useGenericData();
        this.genericCreate = genericCreate
        this.postError = postError
        this.postLoading = postLoading
        this.genericEdit = genericEdit
        this.editLoading = editLoading
        this.editError = editError
    },

    methods: {
        async createItem() {
            const response = await this.genericCreate(this.formData)

            if (!this.postError) {
                this.resetFormData()
                this.$emit('created', response.id)
            }
        },

        async editItem() {
            await this.genericEdit(this.formData, this.itemID)

            if (!this.editError) {
                this.resetFormData()
                this.$emit('edited')
            }
        },

        resetFormData() {
            (this.genericItemSchema).forEach((element) => {
                this.formData[element.key] = element.value;
            });
        },

        updateFormData(inputData) {
            this.formData[inputData.key] = inputData.value
        },

        checkInvalidProps(inputData) {
            const propsToValidate = this.genericItemSchema
                .filter((item) => item.required)
                .map((item) => {
                    return { name: item.name, key: item.key }
                })

            const invalidProps = propsToValidate.filter((item) => {
                if (!this.formData[item.key]) {
                    return item;
                }
            })

            return invalidProps
        },

        handleSubmit(e) {
            if (e) {
                e.preventDefault()
            }

            const invalidProps = this.checkInvalidProps()

            if (invalidProps.length) {
                this.validationErrors = invalidProps
                return
            } else {
                this.validationErrors = []
            }

            if (this.itemID) {
                this.editItem()
            } else {
                this.createItem()
            }
        },

        cancel() {
            this.$emit('canceled')
        }
    }
}
</script>

<style scoped lang='scss'>
.no-actions {
    pointer-events: none;
}

.text-error {
    color: #ff5252;
    margin-left: 16px;
}
</style>
