
import axios from "axios";

export async function getService(url?: string, method = "get", config = {}) {
    try {

        const jwt = await window.vueinstance.__proto__.$getCurrentUserJwToken().toPromise();
        if (method == "POST" || method == "PATCH") {
            return await axios({
                baseURL: `${url}`,
                method: method,
                headers: {
                    Authorization: `${jwt}`,
                    'content-type': "application/json"
                },
                data: config
            });
        } else {
            return await axios({
                baseURL: `${url}`,
                method: method,
                headers: {
                    Authorization: `${jwt}`,
                    'content-type': "application/json"
                },
                ...config
            });
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
