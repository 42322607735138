<template>
  <section class="news-management main-body">

    <div class="news">
      <MiniHeader :heading="'News pages'" :buttonText="'Add News Post'" :hasButton="true" @buttonClicked="createPost()" />

      <section class="search-bar">
        <v-text-field flat solo type="text" v-model="searchQuery"/>
        <v-btn type="button" @click="searchNews()">Search</v-btn>
      </section>

      <div class="search-results">
        <template v-if="searchResults.length > 0">
          <p>Found {{searchResults.length}} Results</p>
          <ul>
            <li v-for="result in searchResults" :key="result.id" class="search-results__item">
              <span class="search-results__detail">
                <router-link class="search-results__name" :to="`/news/${result.id}`">{{ result.fields.title[0] }}</router-link>
                <p class="search-results__slug">{{ result.fields.slug[0] }}</p>
              </span>
              <span class="search-results__meta">
                <i class="search-results__published">Published: {{ result.fields.published_date[0] | moment('DD/MM/YY - kk:mm') }}</i>
                <span v-if="result.fields.published[0] === 'true'" class="search-results__status search-results__status--published">Published</span>
                <span v-else class="search-results__status search-results__status--draft">Draft</span>
              </span>
            </li>
          </ul>
        </template>

        <p v-else-if="searched && searchResults.length === 0">No results found</p>
      </div>

      <div class="breaking-news" v-if="featureConfig.default.BreakingNews">
        <breaking-news :showTitle="true" />
      </div>

      <div class="featured-news">
        <FeaturedArticles :featuredTitle = "'Featured News Articles'" :featuredItems = "featuredNewsItems" @updateFeaturedNewsItems="reorderFeaturedNewsItems" @removeItem="removeFeaturedNewsItem" :allowFeaturedRemove="allowFeaturedRemove"/>

        <v-btn type="button" color="accent" class="featured-news__button" @click="updateFeaturedNewsItems()">Save featured</v-btn>
      </div>

      <NewsListItems v-if="newsLoaded" :newsList ="newsList" :featuredItems = "featuredNewsItems" @addFeaturedItem="addFeaturedItem" @deleteItem="deleteItem" @editItem="editItem" @copyItem="copyItem"/>
      <div v-else class="news-loader">
        <p>loading news...</p>
        <v-progress-circular indeterminate color="#67247c" />
      </div>
    </div>

    <div class="pagination">
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        :total-visible="10"
        circle
        color="accent">
      </v-pagination>
    </div>

  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import FeaturedArticles from "@/components/Library/FeaturedArticles"
import BreakingNews from "@/components/Library/BreakingNews"
import NewsListItems from "@/components/News/NewsListItems"

export default {
  name: "NewsManagement",

  components: {
    MiniHeader,
    FeaturedArticles,
    BreakingNews,
    NewsListItems
  },

  data: () => ({
    api: `${process.env.VUE_APP_NEWSAPI}/news`,
    searchApi: `${process.env.VUE_APP_NEWSAPI}/news/search`,
    newsList: [],
    featuredNewsItems: [],
    featuredNewsChanged: false,
    newsLoaded: false,
    pageCount: 0,
    pageSize: 20,
    pageNumber: 1,
    searchQuery: "",
    searchResults: [],
    searched: false,
    // allowFeaturedAdd: true,
    allowFeaturedRemove: false,
    // maxFeaturedPosts: 5,
    featureConfig: null
  }),

  created() {
    this.featureConfig = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/modules.js")
  },

  mounted() {
    this.fetchNews();
    this.getFeaturedNews();
  },

  beforeRouteLeave (to, from , next) {
    if (this.featuredNewsChanged) {
      const answer = window.confirm("Do you really want to leave? You have unsaved changes to the featured news!")
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  watch: {
    pageNumber(value, oldValue) {
      console.log("Page number changed");
      if (oldValue) {
        this.newsLoaded = false
        this.fetchNews()
      }
    },
  },

  methods: {
    fetchNews() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}?pageSize=${this.pageSize}&pageNumber=${this.pageNumber}`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("response: ", response);
          this.newsLoaded = true;
          this.pageCount = Math.ceil(response.data.totalCount / this.pageSize);
          this.newsList = response.data.body;
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem retrieving pages", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    getFeaturedNews() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios
        .get(`${this.api}/featured`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("getFeaturedNews response: ", response);
          this.featuredNewsItems = response.data ? response.data.body : [];
          
          this.featuredNewsLimiter()

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem getting featured news pages", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    createPost() {
      this.$store.commit("startLoading");
      this.$router.push({ path: "/news/create-post" })
    },

    editItem(postID) {
      this.$store.commit("startLoading");
      this.$router.push({ path: `/news/${postID}` })
    },

    deleteItem(postID) {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
      axios.delete(`${this.api}/${postID}`, {"headers": { "Authorization": jwt }})
        .then(() => {
          console.log("Delete news item", postID);
          this.getFeaturedNews();
          this.fetchNews();
        }).catch(e => {
          console.error("Problem deleting post: ", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    copyItem(postID) {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_NEWSAPI}/news/duplicate/${postID}`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log("Duplicate news item response:", response);
          this.fetchNews();
        })
        .catch(e => {
          console.error("Problem duplicating news item", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem duplicating item";

          this.$store.commit("completeLoading");
        });
      })
    },

    addFeaturedItem(postID, title) {
      const store = this.featuredNewsItems ? this.featuredNewsItems : [];
      const existing = store.filter(item => item.postID === postID);

      this.featuredNewsChanged = true

      console.log("featured items add:", store.length);

      // if (store.length <= this.maxFeaturedPosts) {
        if(!existing.length) {
          store.unshift({
            "postID": postID,
            "postTitle": title
          });
          this.featuredNewsItems = store;
        }
        else {
          this.removeFeaturedNewsItem(postID);
        }
      // }
    },

    reorderFeaturedNewsItems(data) {
      this.featuredNewsItems = data
      this.featuredNewsChanged = true
    },

    updateFeaturedNewsItems(data) {
      this.$store.commit("startLoading");
      console.log("Update featured news item:", data);
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/featured`,
          headers: { "Authorization": jwt },
          data: this.featuredNewsItems
        })
        .then(response => {
          console.log("Updating featured items - response: ", response);
          this.$store.commit("completeLoading");

          this.featuredNewsLimiter()

          this.$gcPublish(["/,/news"]).then((success) => {
            console.log("Publish homepage and news", success)
          })

          this.featuredNewsChanged = false
        })
        .catch(e => {
          console.error("Problem updating featured news pages", e);
          this.$store.commit("completeLoading");
        });
      })
    },

    removeFeaturedNewsItem(id) {
      console.log("Remove featured news item...", id);
      const store = this.featuredNewsItems.filter(item => item.postID !== id);
      this.featuredNewsItems = store;
      this.featuredNewsChanged = true

      console.log("featured items add:", store.length);
    },

    searchNews() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.searchApi}?query=${this.searchQuery}&size=100&start=0&sort=published_date%20desc`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Search news - response: ", response);
          this.searchResults = response.data.hits.hit
          this.searched = true
          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem finding news", e);
          this.$store.commit("completeLoading");
        });
      });
    },

    featuredNewsLimiter() {
      // if (this.featuredNewsItems.length < this.maxFeaturedPosts) {
      //   this.allowFeaturedAdd = true
      // } else {
      //   this.allowFeaturedAdd = false
      // }

      if (this.featuredNewsItems.length <= 1) {
        this.allowFeaturedRemove = false
      } else {
        this.allowFeaturedRemove = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 320px;
  }

  .search-results {
    margin: 0 0 32px;

    ul {
      padding: 0;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px;
      margin: 4px 0;
      background-color: #fff;
      border: 1px solid #ebebeb;
    }

    &__slug {
      display: block;
      margin: 4px 0 0;
      font-size: 10px;
    }

    &__meta {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin: 0 0 0 auto;
      min-width: 220px;
    }

    &__published {
      display: block;
      margin: 4px 0 0;
      font-size: 10px;
    }

    &__status {
      padding: 3px 12px;
      margin: 0 0 0 0;
      font-size: 10px;
      color: #fff;
      background-color: #E6E8F1;
      border-radius: 4px;

      &--published {
        background-color: color(newHighlight);
      }

      &--draft {
        background-color: color(draftHighlight);
      }
    }
  }

  .featured-news {
    padding: 16px 16px 8px;
    background-color: #ebebeb;
    border: 1px solid #E6E8F1;
    border-radius: 6px;

    &__button {
      margin: 0 0 16px;
    }
  }

  .breaking-news {
    margin-bottom: 16px;
  }

  .news-loader {
    margin: 8px auto;
  }
</style>
