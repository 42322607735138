<template>
  <div v-if="promos">
    <div class="promo__item">
      <div class="promo__item-title">
          <h4>{{promoCategory}}</h4>
          <span v-if="promos.length" class="promo__item-promos-count"><v-icon color="white">widgets</v-icon>{{promos.length}}</span>
      </div>
      <div v-if="promos.length" class="promo__item-promos">
        <div class="promo__item-promo" v-for="(promo, index) in promos" :key="index">
          {{promo.name}}
        </div>
      </div>
      <div class="promo__item-actions">
        <v-btn
          color="accent"
          depressed
          outline
          @click="deletePromoDialog=true"
        >
          Delete
        </v-btn>
        <v-btn
          color="accent"
          depressed
          outline
          @click="promoCategoryValid = '', editPromoData.promoCategory = promoCategory, editPromoDialog=true"
        >
          Edit
        </v-btn>
        <v-btn
          color="accent"
          depressed
          @click="editPromo()"
        ><v-icon small>add</v-icon> Add / Edit Promos
        </v-btn>
      </div>
    </div>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="deletePromoDialog" persistent max-width="360">
          <v-card>
            <v-card-title class="headline">Delete Promotion Catergory</v-card-title>
            <v-card-text>Do you wish to delete <strong>{{promoCategory}}</strong> from the promotion listings?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="deletePromoDialog = false">Cancel</v-btn>
              <v-btn depressed color="error" @click="deletePromoDialog  = false, deletePromo(promoCategoryID)">DELETE</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="editPromoDialog" persistent width="580" lazy>
          <v-card>
            <v-card-title class="headline">Edit Promotion Catergory</v-card-title>
            <v-card-text>
              <v-form enctype="multipart/form-data" ref="editPromoForm" v-model="editPromoFormValid" lazy-validation>
                <label class="label" for="name">Promo Catergory Name</label>
                <v-text-field
                  id="Name"
                  v-model="editPromoData.promoCategory"
                  placeholder="Promo Catergory Name"
                  solo
                  flat
                  required
                  :rules="[rules.required]"
                  validate-on-blur
                  label="Promo Catergory Name"
                ></v-text-field>
                <span v-if="promoCategoryValid" class="error--text">{{promoCategoryValid}}</span>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="editPromoDialog = false">Cancel</v-btn>
              <v-btn :disabled="!editPromoData.promoCategory" depressed color="accent" @click="validate()">Save Promo category</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "PromoCategoryRow",

  props: {
    promoCategory: {
      type: String,
      required: true
    },
    promoCategoryID: {
      type: String,
      required: true
    },
    promos: {
      type: Array,
      required: false
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/promocategories`,
    deletePromoDialog: false,
    editPromoDialog: false,
    editPromoFormValid: false,
    promoCategoryValid: "",
    rules: {
      required: value => !!value || "Required."
    },
    editPromoData: {
      promoCategoryID: null,
      promoCategory: null,
      promos: []
    }
  }),

  mounted() {
    console.log("Promo Page Row mounted");
    this.editPromoData.promoCategoryID = this.promoCategoryID;
    this.editPromoData.promoCategory = this.promoCategory;
    this.editPromoData.promos = this.promos;
  },

  methods: {
    editPromo() {
      this.$store.commit("startLoading");
      this.$router.push({ path: `/promos/${this.promoCategoryID}` })
    },
    deletePromo() {
      this.$emit("deletePromo", this.promoCategoryID);
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.editPromoForm.validate()) {
        console.log("Validation passed");
        this.savePromoCat();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    },
    savePromoCat() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: this.api,
          headers: { "Authorization": jwt },
          data: this.editPromoData
        })
        .then(response => {
          console.log("Saved promo category: ", response);
          const status = response.data.success;
          if(status) {
            this.editPromoDialog = false;
            this.$emit("getPromos");
          }
          else {
            this.promoCategoryValid = "The category promo name is already in use"
          }
        })
        .catch(e => {
          console.error("Problem adding promo category: ", e);
        });
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .promo {
    &__item {
      padding: 20px;
      border: 1px solid $lightPurple;
      background-color: rgba($lightergrey, 0.3);
      border-radius: 3px;
      margin-bottom: 10px;
      &-title {
        align-items: center;
        display: flex;
        h4 {
          margin-right: 10px;
        }
      }
      &-promo {
        margin-top: 5px;
        &s {
          margin-bottom: 5px;
          font-size: 85%;
          color: $lightPurple;
          &-count {
            background-color: $purple;
            color: $white;
            padding: 5px 10px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            .v-icon {
              font-size: 100%;
              margin-right: 5px;
            }
          }
        }
      }
      &-actions {
        display: flex;
        justify-content: flex-end;
      }
      &-title {
        h4 {
          color: $purple;
        }
      }
    }
  }
</style>
