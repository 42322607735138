
import TinymceEditor from "@tinymce/tinymce-vue";
import { defineComponent, ref, nextTick } from 'vue';

export default defineComponent({
    name: "GenericRichTextInput",

    components: {
        TinymceEditor
    },

    props: {
        genericFormItemData: {
            type: Object,
            required: true,
        },
    },

    setup(props, { emit }) {
        const input = ref(props.genericFormItemData.value || "");

        //@ts-ignore
        function handleTinyMce(ed) {
            ed.on('blur', function () {
                nextTick(() => {
                    emit('updated', input.value);
                });
            });
        }

        return {
            input,
            handleTinyMce
        }
    }
})

