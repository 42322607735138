
import ListItem from "../ListItem.vue";
import { defineComponent, ref } from "vue";
import GenericItemModal from "./GenericItemModal.vue";
import { IGenericFormItem } from "../../../types/genericData";
//@ts-ignore
import moment from "moment"

export default defineComponent({
    name: "GenericListItem",

    components: {
        ListItem, GenericItemModal
    },
    props: {
        id: {
            type: String,
            required: true
        },
        genericItemData: {
            type: Object,
            required: true
        },
        propertyMap: {
            type: Object,
            required: true
        },
        sectionData: {
            type: Object,
            required: true,
        }

    },

    setup(props, { emit }) {
        // Setup
        const dialog = ref(false)
        const { genericDelete, deleteError } = props.sectionData.useGenericData()

        const nameProp = props.propertyMap["name"]
        const name = props.genericItemData[nameProp]

        const descriptionProp = props.propertyMap["description"]
        const description = props.genericItemData[descriptionProp]

        const dateProp = props.propertyMap["date"]
        let date = props.genericItemData[dateProp]
        if (date) {
            date = moment(date).format('DD MMM YYYY, HH:mm');
        }

        const labelProp = props.propertyMap["label"]
        const label = props.genericItemData[labelProp]

        const activeLabel = props.propertyMap.activeLabel

        // Functions
        function goToItem() {
            //@ts-ignore
            window.globalThis.vueinstance.$router.push(`${props.sectionData.baseURL}/${props.id}`)
        }

        function handleEdit() {
            if (props.sectionData.edit.type == "navigation") {
                goToItem()
            } else {
                dialog.value = true
            }
        }

        async function handleDelete() {
            await genericDelete(props.id)
            if (deleteError) {
                window.alert("An error has occurred")
            } else {
                emit('deleted')
            }
        }

        const updatedEditSchema = props.sectionData.edit.schema ? props.sectionData.edit.schema.map((item: IGenericFormItem) => {
            const propName = item.key
            const modifiedItem = {
                ...item,
            }
            modifiedItem.value = props.genericItemData[propName]
            return modifiedItem
        }) : []


        function handleEditedItem() {
            dialog.value = false
            emit("edited")
        }

        return {
            name,
            description,
            date,
            label,
            activeLabel,
            goToItem,
            handleEdit,
            handleEditedItem,
            handleDelete,
            dialog,
            updatedEditSchema
        }
    }
})
