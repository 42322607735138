<template>
  <section :class="['breaking-news',  showTitle ? 'breaking-news__normal' : 'breaking-news__light']" v-if="breakingNewsPost">
    <div class="wrapper" >
      <h2 v-if="showTitle" class="featured__subheading subheading">Breaking News</h2>

      <p><b><a @click="goToPost()">{{breakingNewsPost.postTitle}}</a></b> is set as the breaking news story until <i>{{breakingNewsPost.breakingNewsEnd | moment('DD/MM/YYYY - HH:mm:ss')}}</i><template v-if="publishMessage"> and is due to be published at <i>{{publishDatePretty}}</i></template>.
      </p>

      <p>To unset please remove the breaking news flag in the admin.</p>
    </div>

    <img class="breaking-news__illustration" :src="require('../../assets/illustrations/breaking-news.png')" />

  </section>

</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
  name: "BreakingNews",

  props: {
    showTitle: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    api: `${process.env.VUE_APP_NEWSAPI}`,
    breakingNewsPost: null,
    publishMessage: null,
    publishDatePretty: null
  }),

  mounted() {
    this.fetchBreakingStory()
  },

  methods: {
    fetchBreakingStory() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(`${this.api}/news/breaking`, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("breaking response: ", response);
          this.$store.commit("completeLoading");
          this.breakingNewsPost = response.data.body

          if (response.data.body && response.data.body.publishedDateTime) {

            var publishDate = new Date(response.data.body.publishedDateTime).getTime()
            this.publishDatePretty = moment(new Date(response.data.body.publishedDateTime)).format("DD/MM/YYYY - HH:mm:ss")
            if (publishDate > new Date().getTime()) {
              this.publishMessage = true
            }
          }
        })
        .catch(e => {
          console.error("Problem retrieving breaking news", e);
          this.$store.commit("completeLoading");
        });
      })
    },
    goToPost() {
      this.$store.commit("startLoading");
      this.$router.push({ name: "News Editor", params: {id: this.breakingNewsPost.postID} })
    }
  }
}
</script>

<style scoped lang='scss'>

.breaking-news {
  position: relative;
  background-color: #ebebeb;
  border: 1px solid #E6E8F1;
  border-radius: 6px;

  &__normal {
    padding: 16px 16px 8px;
  }

  &__light {
    padding: 32px 16px 24px;
  }

  &__illustration {
    position: absolute;
    top: -17px;
    right: 10px;
    width: 160px;
  }
}

.wrapper {
  margin-right: 160px;
}

</style>
