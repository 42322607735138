<template>
  <div>
    <ol v-if="newsList.length > 0" class="news-list">
      <li class="news-item" v-for="item in newsList" :key="item.postID" :class="[(item.deleteStatus && item.deleteStatus === 'deleting') ? 'deleting' : '', (item.copyStatus && item.copyStatus === 'copying') ? 'copying' : '']">
        <div class="news-item__info">
          <time class="news-item__date" :datetime="item.publishedDateTime">{{ item.publishedDateTime | moment('DD/MM/YY - kk:mm') }}</time>

          <h2 class="news-item__title" v-if="item.postTitle">{{ item.postTitle }} <span v-if="item.postStatus != 'Published'" class="news-item__status">{{ item.postStatus }}</span></h2>
          <h2 class="news-item__title" v-else>Title not set</h2>
        </div>

        <div class="news-item__actions">
          <!-- <div
            v-if="!featured(item.postID).length && item.postStatus == 'Published' && allowFeaturedAdd"
            :class="'news-item__feature'"
            @click="addFeaturedItem(item.postID, item.postTitle)"
          > -->
          <div
            v-if="!featured(item.postID).length && item.postStatus == 'Published'"
            :class="'news-item__feature'"
            @click="addFeaturedItem(item.postID, item.postTitle)"
          >
            Feature
          </div>

          <!-- <div
            v-if="featured(item.postID).length && allowFeaturedRemove"
            :class="'news-item__feature news-item__feature--featured'"
            @click="addFeaturedItem(item.postID, item.postTitle)"
          > -->
          <div
            v-if="featured(item.postID).length"
            :class="'news-item__feature news-item__feature--featured'"
            @click="addFeaturedItem(item.postID, item.postTitle)"
          >
            <span>Feature</span><v-icon dark right>check_circle</v-icon>
          </div>

          <button class="news-item__action" type="button" @click="dialogEdit = true, id = item.postID, name = item.postTitle">
            <v-icon>file_copy</v-icon>
          </button>

          <button class="news-item__action" type="button" @click="dialogDelete = true, id = item.postID, name = item.postTitle">
            <v-icon>delete</v-icon>
          </button>

          <button class="news-item__action" type="button" @click="editItem(item.postID)">
            <v-icon>edit</v-icon>
          </button>
        </div>
      </li>
    </ol>
    <p v-else>No news articles yet</p>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="dialogDelete" persistent max-width="360">
          <v-card>
            <v-card-title class="headline">Delete news article?</v-card-title>
            <v-card-text>Do you wish to delete <strong>{{name}}</strong> from the news article section?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="dialogDelete = false">Cancel</v-btn>
              <v-btn depressed color="error" @click="dialogDelete  = false, deleteItem(id)">DELETE</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEdit" persistent max-width="360">
          <v-card>
            <v-card-title class="headline">Copy news article</v-card-title>
            <v-card-text>Do you wish to make a copy of <strong>{{name}}?</strong></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="dialogEdit = false">Cancel</v-btn>
              <v-btn depressed color="accent" @click="dialogEdit  = false, copyItem(id)">Copy</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
  </div>
</template>

<script>
export default {
  name: "NewsListItems",

  props: {
    newsList: {
      type: Array,
      required: true
    },
    featuredItems: {
      type: Array,
      required: true
    },
    // allowFeaturedAdd: {
    //   type: Boolean,
    //   required: true
    // },
    // allowFeaturedRemove: {
    //   type: Boolean,
    //   required: true
    // },
  },

  data: () => ({
    dialogDelete: false,
    dialogEdit: false,
    id: false,
    name: false
  }),

  mounted() {
    console.log("News list items mounted");
  },

  methods: {
    addFeaturedItem(id, title) {
      this.$emit("addFeaturedItem", id, title);
    },

    featured(id) {
      const featured = this.featuredItems.filter(item => item.postID === id);
      return featured;
    },

    deleteItem(id) {
      this.$emit("deleteItem", id);
      for (const item of this.newsList) {
        console.log("item", item)
        if (item.postID === id) {
          item.deleteStatus = "deleting"
        }
      }
    },

    editItem(id) {
      this.$emit("editItem", id);
    },

    copyItem(id) {
      this.$emit("copyItem", id);
      for (const item of this.newsList) {
        console.log("item", item)
        if (item.postID === id) {
          item.copyStatus = "copying"
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .news-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .news-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;

    &__status {
      padding: 3px 17px;
      margin: 1px 8px 0 5px;
      font-size: 12px;
      color: #fff;
      background-color: color(draftHighlight);
      border-radius: 4px;
    }

    &__info {
      flex-grow: 1;
    }

    &__date {
      display: block;
      margin: 0 0 8px;
      color: #B8B8B8;
      font-size: 13px;
      font-weight: 300;
    }

    &__title {
      color: #7B6B80;
      font-size: 16px;
      font-weight: 400;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &__action {
      margin: 0 0 0 20px;
    }

    &__feature {
      border: 1px solid $purple;
      padding: 5px 10px;
      border-radius: 3px;
      color: $purple;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      &--featured {
        background-color: $purple;
        color: $white;
      }
    }
  }

  .deleting {
    display: none;
  }

  .copying {
    position: relative;
    opacity: 0.5;

    &:before {
      content: 'copy in progress...';
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
</style>
