<template>
    <section class="sso-management main-body">

        <div>
            <div class="create-buttons">
                <v-btn
                    v-if="modules.default.SsoUsers"
                    class="create-buttons__add-user"
                    color="accent"
                    dark
                    type="button"
                    depressed
                    @click="addUser()"
                >
                    Add User
                </v-btn>

                <v-btn
                    v-if="modules.default.Streamline"
                    class="create-buttons__add-entitlement"
                    color="accent"
                    dark
                    type="button"
                    depressed
                    @click="addEntitlement()"
                >
                    Add User Entitlement
                </v-btn>
            </div>

            <div v-if="modules.default.Streamline && modules.default.SsoUsers">
                <button
                    @click="updateSearchTarget('gamechanger')"
                    :class="{ 'is-active': searchTarget === 'gamechanger' }"
                    type="button"
                    class="search-users-toggle"
                >Gamechanger</button>
                <button
                    @click="updateSearchTarget('streamline')"
                    :class="{ 'is-active': searchTarget === 'streamline' }"
                    type="button"
                    class="search-users-toggle"
                >Streamline</button>
            </div>
            <div class="search-users">
                <div class="search-users__section search-users__section--select">
                    <label
                        for="field"
                        class="search-users__label"
                    >Field to search</label>
                    <v-select
                        id="field"
                        item-text='name'
                        item-value='value'
                        :items="searchOptions"
                        v-model="searchFieldParameter"
                        solo
                        flat
                    ></v-select>
                </div>

                <div class="search-users__section search-users__section--text-field">
                    <label
                        for="search-text"
                        class="search-users__label"
                    >Starts with:</label>
                    <v-text-field
                        id="search-text"
                        placeholder=""
                        solo
                        flat
                        v-model="searchText"
                    ></v-text-field>
                </div>

                <div class="search-users__section">
                    <v-btn
                        :disabled="searchText === '' || searching"
                        accent
                        type="button"
                        @click="fetchUsers()"
                    >
                        Search Users
                    </v-btn>
                </div>
            </div>

            <p v-if="modules.default.Streamline && modules.default.PreventEntitlementEmails">This configuration is prevented from searching
                via email</p>

            <p v-if="searching">Searching...</p>

            <table
                v-else
                class="sso-users"
            >
                <template v-if="searchTarget === 'gamechanger'">
                    <thead>
                        <tr>
                            <th>Email/Username</th>
                            <th>First Name</th>
                            <th>Family Name</th>
                            <th>Enabled</th>
                            <th>Status</th>
                            <th>Last Modified Date</th>
                            <th>User Created</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="userList.length > 0">
                        <tr
                            v-for="item in userList"
                            :key="item.Username"
                        >
                            <td>
                                <span class="sso-users__email">{{ getAttribute(item, 'email') }}</span>
                                <span class="sso-users__username">{{ item.id }}</span>
                            </td>
                            <td>{{ getAttribute(item, 'given_name') }}</td>
                            <td>{{ getAttribute(item, 'family_name') }}</td>
                            <td>{{ item.attributes.enabled }}</td>
                            <td :class="`sso-users__status--${item.attributes.status && item.attributes.status.toLowerCase()}`">{{
                                item.attributes.status }}</td>
                            <td>{{ item.attributes.lastModifiedAt | moment('DD/MM/YYYY (HH:mm:ss)') }}</td>
                            <td>{{ item.attributes.createdAt | moment('DD/MM/YYYY (HH:mm:ss)') }}</td>
                            <td>
                                <button
                                    class="user-item__action"
                                    type="button"
                                    @click="editItem(item.id)"
                                >
                                    <v-icon>edit</v-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6">No users to display</td>
                        </tr>
                    </tbody>
                </template>

                <template v-else-if="searchTarget === 'streamline'">
                    <thead>
                        <tr>
                            <th>Email/Username</th>
                            <th>SSO ID</th>
                            <th>Bypass Checks</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="userList.length > 0">
                        <tr
                            v-for="item in userList"
                            :key="item.id"
                        >
                            <td>
                                <span class="sso-users__email">{{ item.attributes.userEmail }}</span>
                            </td>
                            <td>{{ item.id }}</td>
                            <td>{{ item.attributes.bypassStreamlineSessionChecks ? 'Yes' : 'No' }}</td>
                            <td>
                                <button
                                    class="user-item__action"
                                    type="button"
                                    @click="editItem(item.id)"
                                >
                                    <v-icon>edit</v-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6">No users to display</td>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
        <v-pagination
            v-if="pageCount"
            v-model="pageNumber"
            :length="pageCount"
            :total-visible="7"
            circle
            color="accent"
        >
        </v-pagination>
    </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"

export default {
    name: "Users",

    components: {
        MiniHeader
    },

    data: () => ({
        cognitoAPI: `${process.env.VUE_APP_SSO_COGNITO_API}`,
        streamlineAPI: `${process.env.VUE_APP_STREAMLINEAPI}/users/`,
        modules: null,
        userList: [],
        searchFieldParameter: "email",
        searchText: "",
        searching: false,
        searchTarget: "", // gamechanger, streamline
        searchOptions: [],
        searchOptionsGamechanger: [
            { name: "SSO ID", value: "username" },
            { name: "Email", value: "email" },
            { name: "Given Name", value: "given_name" },
            { name: "Family Name", value: "family_name" }
        ],
        searchOptionsStreamline: [
            { name: "SSO ID", value: "userID" },
            { name: "Email", value: "userEmail" },
        ],
        pageNumber: 1,
        pageCount: null,
    }),

    created() {
        this.modules = require("../../../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")
    },

    mounted() {
        // this.fetchUsers();
        this.initSettings()
    },

    watch: {
        pageNumber() {
            this.fetchUsers()
        },
    },

    methods: {
        initSettings() {
            if (this.modules.default.SsoUsers) {
                this.searchTarget = "gamechanger"
                this.searchOptions = this.searchOptionsGamechanger
                this.searchFieldParameter = "email"
            } else {
                this.searchTarget = "streamline"
                this.searchOptions = this.searchOptionsStreamline
                this.searchFieldParameter = "userEmail"

                if (this.modules.default.PreventEntitlementEmails) {
                    this.searchOptions = [
                        { name: "SSO ID", value: "userID" },
                    ]

                    this.searchFieldParameter = "userID"
                }
            }
        },

        updateSearchTarget(changeTo) {
            console.log("updateSearchTarget to:", changeTo)
            this.searchFieldParameter = ""
            this.searchText = ""
            this.userList = []
            this.searchTarget = changeTo

            if (changeTo === "gamechanger") {
                this.searchOptions = this.searchOptionsGamechanger
                this.searchFieldParameter = "email"
            } else {
                this.searchOptions = this.searchOptionsStreamline
                this.searchFieldParameter = "userEmail"

                if (this.modules.default.PreventEntitlementEmails) {
                    this.searchOptions = [
                        { name: "SSO ID", value: "userID" },
                    ]

                    this.searchFieldParameter = "userID"
                }
            }
        },

        getAttribute(item, attributeName) {
            return item.attributes[attributeName] || "--"
        },

        fetchUsers() {
            this.$store.commit("startLoading");
            this.searching = true

            if (this.searchTarget === "gamechanger") {

                const searchRequest = `${this.cognitoAPI}/?field=${this.searchFieldParameter}&filter=${this.searchText}&page.number=${this.pageNumber}&page.size=100`
                console.log("searchRequest example", searchRequest)

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "GET",
                        url: searchRequest,
                        headers: { "Authorization": jwt }
                    })
                        .then(response => {
                            console.log("Users", response);
                            this.userList = response.data.data
                            console.log(this.userList);
                        })
                        .catch(e => {
                            console.error("Error loading admin users:", e);
                        }).finally(() => {
                            this.searching = false;
                        })
                    this.$store.commit("completeLoading");
                })

            } else if (this.searchTarget === "streamline") {

                const searchRequest = `${this.streamlineAPI}?filter[${this.searchFieldParameter},contains,string]=${this.searchText}&page[number]=${this.pageNumber}&page[size]=100`
                console.log("searchRequest example", searchRequest)

                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "GET",
                        url: searchRequest,
                        headers: { "Authorization": jwt }
                    })
                        .then(response => {
                            console.log("Users", response);
                            this.userList = response.data.data
                            console.log(this.userList);
                            this.pageCount = response.data.meta.totalPages
                        })
                        .catch(e => {
                            console.error("Error loading admin users:", e);
                        }).finally(() => {
                            this.searching = false;
                        })
                    this.$store.commit("completeLoading");
                })
            }
        },

        editItem(itemID) {
            this.$store.commit("startLoading");
            this.$router.push({ path: `/users/edit/${itemID}` })
        },

        addUser() {
            this.$store.commit("startLoading");
            this.$router.push({
                path: "/users/add/"
            })
        },

        addEntitlement() {
            this.$router.push({
                path: "/users/create-entitlement/"
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.search-users {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
    padding: 16px 8px;
    background-color: #fff;
    border: 1px solid #ccc;

    &__section {
        padding: 0 8px;

        &--select {
            width: 170px;
        }

        &--text-field {
            width: 50%;
        }
    }

    &__label {
        display: block;
        margin: 0 0 4px;
    }
}

.create-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &__add-entitlement {
        margin-left: 16px;
    }
}

.search-users-toggle {
    padding: 4px 8px;
    margin: 0 8px 0 0;
    opacity: 0.6;

    &.is-active {
        color: #fff;
        background: #67247c;
        opacity: 1;
    }
}

.sso-users {
    width: 100%;

    th {
        padding: 8px 0;
        font-size: 12px;
        text-align: left;
        border-bottom: 1px solid rgba(209, 216, 245, 0.6);
    }

    td {
        padding: 8px 0;
        font-size: 12px;
        border-bottom: 1px solid rgba(209, 216, 245, 0.6);
    }

    &__email {
        display: block;
        margin-bottom: 4px;
    }

    &__username {
        display: block;
        opacity: 0.4;
    }

    &__status {
        &--confirmed {
            color: green;
        }

        &--unconfirmed {
            color: red;
        }
    }

    &__action {
        margin: 0 0 0 20px;
    }
}
</style>
