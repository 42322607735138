<template>
    <div class="confirmation-modal">
        <div
            v-if="showModal"
            class="confirmation-modal__overlay"
        >
            <div class="confirmation-modal__modal">
                <h2 class="confirmation-modal__title">
                    {{ title }}
                </h2>
                <p class="confirmation-modal__text">{{ text }}</p>
                <v-text-field
                    v-if="hasInput"
                    solo
                    flat
                    v-model="inputData"
                    :type="inputType"
                />
                <div class="confirmation-modal__buttons">
                    <v-btn
                        v-if="singleOption"
                        color="accent"
                        class="confirmation-modal__button confirmation-modal__confirm"
                        @click="
                $emit('canceled');
            showModal = false;
            "
                    >{{ singleButton }}</v-btn>
                    <template v-else>
                        <v-btn
                            class="confirmation-modal__button confirmation-modal__cancel"
                            @click="
                $emit('canceled');
            showModal = false;
            "
                        >{{ cancelButton }}</v-btn>
                        <v-btn
                            color="accent"
                            class="confirmation-modal__button confirmation-modal__confirm"
                            @click="
                $emit('confirmed', inputData);
            showModal = false;
            "
                        >{{ confirmButton }}</v-btn>
                    </template>
                </div>
            </div>
        </div>
        <div @click="handleClick">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConfirmationModal",

    props: {
        title: {
            type: String,
            default: "Delete Item",
        },
        text: {
            type: String,
            default: "Are you sure you want to delete this item?",
        },
        confirmButton: {
            type: String,
            default: "YES",
        },
        cancelButton: {
            type: String,
            default: "NO",
        },
        singleButton: {
            type: String,
            default: "OK",
        },
        singleOption: {
            type: Boolean,
            default: false,
        },
        passCondition: {
            type: Boolean,
            default: false,
        },
        hasInput: {
            type: Boolean,
            default: false,
        },
        inputType: {
            type: String,
            default: "text"
        }
    },

    data: () => ({
        showModal: false,
        inputData: "",
    }),

    methods: {
        handleClick() {
            if (this.passCondition) {
                this.$emit("confirmed");
            } else {
                this.showModal = true;
            }
        },
    },
};
</script>

<style
    scoped
    lang='scss'
>
.confirmation-modal {
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba($color: #131415, $alpha: 0.4);
        z-index: 999;
    }

    &__modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #ffffff;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 10 16 24 rgba($color: #000000, $alpha: 0.08);
        max-width: 500px;
    }

    &__title {
        font-size: 20px;
        color: #24002f;
        font-weight: 400;
        margin-bottom: 16px;
    }

    &__text {
        font-size: 14px;
        color: #516173;
    }

    &__buttons {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__button {
        padding: 10px 25px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        border: 0;
        outline: none;
        margin: 0;
        box-shadow: none !important;
    }

    &__cancel {
        background: transparent;
        background-color: transparent !important;
        color: #516173;

        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.4);
        }
    }

    &__confirm {
        margin-left: 10px;
        color: white;
    }
}
</style>
