
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
    name: "GenericDateInput",


    props: {
        genericFormItemData: {
            type: Object,
            required: true,
        },
    },

    setup(props, { emit }) {
        const input = ref(props.genericFormItemData.value || "");

        watch(input, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                emit("updated", newValue)
            }
        })

        return {
            input,
        }
    }
})
