<template>
  <v-flex class="featured">
    <div class="featured__subheading-container">
      <h2 class="featured__subheading subheading">{{featuredTitle}} ({{updatedFeaturedItems.length}})</h2>
      <img v-if="renderedIcon" :src="require(`@/assets/grid-types/${renderedIcon}.png`)" class="featured__icon" alt="">
    </div>

    <draggable
      v-if="updatedFeaturedItems"
      v-model="updatedFeaturedItems"
      :options="{
        ghostClass: 'ghost',
        dragClass: 'sortable-drag'
      }"
      @start="drag=true"
      @end="drag=false, reorderFeaturedItemsData()">

      <transition-group name="list-complete" class="featured__items">

        <div v-for="item in updatedFeaturedItems" class="featured__item draggable-item" :key="item.postID">
          <div class="featured__item-content">
            <v-icon class="draggable-item__handle-icon">open_with</v-icon>
            <div class="featured__item-content-title">{{item.postTitle}}</div>

            <div class="featured__actions">
              <button class="featured__edit" type="button" @click="editItem(item.postID)">
                <v-icon>edit</v-icon>
              </button>
              <div
                v-if="allowFeaturedRemove"
                class="featured__item-content-action"
                @click="remove(item.postID)">
                <v-icon color="accent" right>remove_circle</v-icon>
              </div>
            </div>
          </div>
        </div>

      </transition-group>
    </draggable>
  </v-flex>
</template>

<script>
import draggable from "vuedraggable"

export default {
  name: "FeaturedArticles",

  components: {
    draggable
  },

  props: {
    featuredTitle: {
      type: String,
      required: true
    },
    featuredItems: {
      type: Array,
      required: true
    },
    allowFeaturedRemove: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    updatedFeaturedItems: [],
    renderedIcon: null
  }),

  created() {
    this.updateIcon()
  },

  mounted() {
    console.log("FeaturedArticles mounted 1", this.featuredItems);
    this.updatedFeaturedItems = this.featuredItems;
    console.log("FeaturedArticles mounted 2", this.updatedFeaturedItems);
  },

  watch: {
    featuredItems: function() {
      this.updatedFeaturedItems = this.featuredItems;
      this.updateIcon()
    },
  },

  methods: {
    remove(id) {
      this.$emit("removeItem", id)
    },

    reorderFeaturedItemsData: function() {
      console.log("Reordered Featured Items");
      this.$emit("updateFeaturedNewsItems", this.updatedFeaturedItems);
    },

    editItem(id) {
      this.$store.commit("startLoading");
      this.$router.push({ path: `/news/${id}` })
    },

    updateIcon() {
      switch (true) {
        case (this.featuredItems.length == 1):
          this.renderedIcon = "1@3x"
          break;

        case (this.featuredItems.length == 2):
          this.renderedIcon = "2@3x"
          break;

        case (this.featuredItems.length == 3):
          this.renderedIcon = "3@3x"
          break;
          
        case (this.featuredItems.length == 4):
          this.renderedIcon = "4@3x"
          break;

        case (this.featuredItems.length >= 5):
          this.renderedIcon = "5@3x"
          break;
      
        default:
          break;
      }
    }
  }

}
</script>

<style lang="scss">
  .featured {
    &__subheading {
      margin-top: 0;
      margin-bottom: 0;
    }

    &__icon {
      height: 50px;
    }

    &__subheading-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    &__item {
      border: 1px solid rgba($purple, 0.7);;
      background-color: rgba($purple, 0.1);
      border-radius: 3px;
      margin-bottom: 20px;

      &-content {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
          color: $purple;
          font-size: 1.4rem;
        }

        &-action {
          cursor: pointer;
        }
      }
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .draggable-item {
    transition: all 0.5s, opacity 0.2s;

    &__handle-icon {
      cursor: pointer;
      padding-right: 20px;
    }
  }
</style>
