import { getService } from "./service"

export async function getAllItems(api: string, pageSize?: number, pageNumber?: number) {
    if (!pageSize && !pageNumber) {
        return getService(api)
    } else return getService(`${api}?page.number=${pageNumber}&page.size=${pageSize}`)
}

export async function postItem<T>(api: string, itemBody: T) {
    return getService(`${api}`, 'POST', { data: itemBody })
}

export async function editItem<T>(api: string, id: string, itemBody: T) {
    return getService(`${api}${id}`, 'PATCH', { data: itemBody })
}

export async function getSingleItem(api: string, id: string) {
    return getService(`${api}${id}`)
}

export async function deleteItem(api: string, id: string) {
    return getService(`${api}${id}`, 'DELETE')
}
