
import { defineComponent, ref } from 'vue';
//@ts-ignore
import ImageUploader from "@/components/Library/ImageUploader";

export default defineComponent({
    name: "GenericIconInput",

    components: {
        ImageUploader
    },

    props: {
        genericFormItemData: {
            type: Object,
            required: true,
        },
    },

    setup(props, { emit }) {

        const input = ref(props.genericFormItemData.value && props.genericFormItemData.value.split(".")[0] || "");

        function selectImage(image: { imageKey: string, mediaLibraryID: string }) {
            emit('updated', image.imageKey)
        }

        return {
            input,
            selectImage
        }
    }
})
