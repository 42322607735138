import { ref, Ref } from "vue"
import { IGenericAPIPaths, IApiResponse, IValidationErrors } from "../types/genericData";
import { getAllItems, getSingleItem, postItem, editItem, deleteItem } from "../api/generic"


export function useGenericData(api: IGenericAPIPaths) {
    const loading = ref(true);
    const error = ref("");
    const pageNumber = ref(1);
    const pageSize = ref(50);
    const pageCount = ref(0);
    const genericItemsModel = ref([]);
    const genericItemModel = ref({});
    const genericOriginalItemModel = ref({});

    const genericGetAll = async (id?: string) => {
        loading.value = true
        const response: IApiResponse<any> | undefined = await getAllItems(`${id && api.dynamicBase ? api.dynamicBase(id) : api.base}${api.getAll}`, pageSize.value, pageNumber.value)
        if (response) {
            genericItemsModel.value = response.data.data
            if (response.meta) {
                pageCount.value = Math.ceil(response.meta.totalCount / response.meta.count)
            }
        } else {
            error.value = "No items found"
            loading.value = false
        }

        loading.value = false

    }

    const genericGetSingle = async (id: string) => {
        loading.value = true
        error.value = ""

        const response: IApiResponse<any> | undefined = await getSingleItem(`${api.base}${api.getAll}`, id)
        if (response) {
            genericOriginalItemModel.value = response.data.data
            genericItemModel.value = response.data.data
        }
        loading.value = false
        if (response && response.status === 200) {
            error.value = ""
        } else {
            error.value = "An error has occurred"
        }

        loading.value = false
    }

    const postLoading = ref(false)
    const postError = ref("")

    const genericCreate = async<T>(postData: T) => {
        postLoading.value = true

        const body = {
            type: api.type,
            attributes: postData
        }

        const response: IApiResponse<any> | undefined = await postItem(`${api.base}${api.getAll}`, body)

        postLoading.value = false
        if (response && response.status === 200) {
            postError.value = ""
            return response.data.data
        } else {
            postError.value = "An error has occurred"
        }
    }

    const editLoading = ref(false)
    const editError = ref("")

    const genericEdit = async<T>(editData: T, id: string) => {
        editLoading.value = true

        const body = {
            type: api.type,
            id,
            attributes: editData
        }

        const response: IApiResponse<any> | undefined = await editItem(`${api.base}${api.getAll}`, id, body)

        editLoading.value = false
        if (response && response.status === 200) {
            editError.value = ""
        } else {
            editError.value = "An error has occurred"
        }
    }

    const deleteLoading = ref(false)
    const deleteError = ref("")

    const genericDelete = async (id: string) => {
        deleteLoading.value = true
        deleteError.value = ""

        const response: IApiResponse<any> | undefined = await deleteItem(`${api.base}${api.getAll}`, id)
        deleteLoading.value = false
        if (response && response.status === 204) {
            deleteError.value = ""
        } else {
            deleteError.value = "An error has occurred"
        }
    }

    return {
        genericItemsModel,
        genericItemModel,
        genericOriginalItemModel,
        genericGetAll,
        genericGetSingle,
        genericCreate,
        genericEdit,
        genericDelete,
        loading,
        error,
        pageNumber,
        pageSize,
        pageCount,
        postLoading,
        postError,
        editLoading,
        editError,
    }
}
