
import { defineComponent, ref } from 'vue';
export default defineComponent({
    name: "GenericSelectInput",

    props: {
        genericFormItemData: {
            type: Object,
            required: true,
        },
    },

    setup(props) {
        const input = ref(props.genericFormItemData.value || "");

        return {
            input
        }
    }
})
