
import { defineComponent, ref } from "vue";
import GenericItemModal from "./GenericItemModal.vue";
//@ts-ignore
import GenericListItem from "./GenericListItem.vue";
//@ts-ignore
import MiniHeader from "@/components/Library/MiniHeader"

export default defineComponent({
    name: "GenericListing",
    components: { GenericListItem, GenericItemModal, MiniHeader },

    props: {
        genericData: {
            type: Object,
            required: true,
        },
        parentID: {
            type: String,
            required: false,
        }
    },
    setup(props, { emit }) {
        const { loading, error, genericItemsModel, genericGetAll, pageNumber, pageCount } = props.genericData.useGenericData();
        const dialog = ref(false)
        const modalReady = ref(true)

        genericGetAll(props.parentID)

        function handleNewItem() {
            if (props.genericData.create.type == "modal") {
                dialog.value = !dialog.value
            } else {
                //@ts-ignore
                window.globalThis.vueinstance.$router.push(`${props.genericData.baseURL}/create`)
            }
        }

        async function handleItemCreated(itemID?: string) {
            modalReady.value = false
            dialog.value = false
            if (props.parentID) {
                emit("childCreated", itemID)
            }
            await genericGetAll(props.parentID)
            modalReady.value = true
        }
        async function handleItemEdited() {
            modalReady.value = false
            dialog.value = false
            await genericGetAll(props.parentID)
            modalReady.value = true
        }
        async function handleItemDeleted(itemID: string,) {
            if (props.parentID) {
                emit("childDeleted", itemID)
            } else {
                genericGetAll()
            }
        }


        return { loading, error, genericItemsModel, dialog, handleNewItem, handleItemCreated, handleItemEdited, handleItemDeleted, modalReady, genericGetAll, pageNumber, pageCount }
    }
})
