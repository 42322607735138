
import ConfirmationModal from "./GlobalModals/ConfirmationModal.vue";
import { defineComponent } from "vue";
export default defineComponent({
    name: "ListItem",

    props: {
        name: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        date: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        activeLabel: {
            type: String,
            required: false,
        }
    },
    components: {
        ConfirmationModal
    },

    setup() {

        return {

        }
    }
})
