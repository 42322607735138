<template>
  <section class="main-body">
    <div>
      <MiniHeader
        :heading="'Promos'"
        :buttonText="'Add Catergory Promo'"
        :hasButton="true"
        @buttonClicked="addPromoDialogBox()"
      />
    </div>
    <div v-if="promoData[0]">
      <PromoCategoryRow
        v-for="(promo, index) in promoData[0]" :key="index"
        :promoCategory = "promo.promoCategory"
        :promoCategoryID = "promo.promoCategoryID"
        :promos = "promo.promos"
        @deletePromo="deletePromo"
        @getPromos="getPromos"
      ></PromoCategoryRow>
    </div>
    <template>
      <v-layout row justify-center>
        <v-dialog v-model="addPromoDialog" persistent width="580" lazy>
          <v-card>
            <v-card-title class="headline">Add Promotion Catergory</v-card-title>
            <v-card-text>
              <v-form enctype="multipart/form-data" ref="addPromoForm" v-model="addPromoFormValid" lazy-validation>
                <label class="label" for="name">Promo Catergory Name</label>
                <v-text-field
                  id="Name"
                  v-model="newPromoData.promoCategory"
                  placeholder="Promo Catergory Name"
                  solo
                  flat
                  required
                  :rules="[rules.required]"
                  validate-on-blur
                  label="Promo Catergory Name"
                ></v-text-field>
                <span v-if="promoCategoryValid" class="error--text">{{promoCategoryValid}}</span>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed @click="addPromoDialog = false">Cancel</v-btn>
              <v-btn :disabled="!newPromoData.promoCategory" depressed color="accent" @click="validate()">Add Promo category</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import PromoCategoryRow from "@/components/Promos/PromoCategoryRow"

export default {
  name: "PromoManagement",

  components: {
    MiniHeader,
    PromoCategoryRow
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/promocategories`,
    promoData: [],
    addPromoDialog: false,
    addPromoFormValid: false,
    promoCategoryValid: "",
    newPromoData: {
      promoCategory: null,
      promos: []

    },
    rules: {
      required: value => !!value || "Required."
    }
  }),

  mounted() {
    console.log("Promo Management mounted");
    this.getPromos();

  },

  methods: {
    addPromoDialogBox() {
      this.promoCategoryValid = "";
      this.newPromoData = {
        promoCategory: null,
        promos: []
      };
      this.addPromoDialog = true;
    },
    addPromo() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: this.api,
          headers: { "Authorization": jwt },
          data: this.newPromoData
        })
        .then(response => {
          console.log("Saved promo category: ", response);
          const status = response.data.success;
          if(status) {
            this.addPromoDialog = false;
            this.getPromos();
          }
          else {
            this.promoCategoryValid = "The category promo name is already in use"
          }
        })
        .catch(e => {
          console.error("Problem adding promo category: ", e);
        });
      })
    },
    getPromos() {
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: this.api,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log(response);
          this.promoData = response.data.body.Items;
          console.log(this.promoData);
        })
        .catch(e => {
          console.error("Error loading promo categories:", e);
        });
        this.$store.commit("completeLoading");
      })
    },
    deletePromo(id) {
      console.log("Deleting...", id);
      this.$store.commit("startLoading");
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "DELETE",
          url: `${this.api}?promoCategoryID=${id}`,
          headers:  { "headers": { "Authorization": jwt }}
        })
        .then(response => {
          this.getPromos();
        })
        .catch(e => {
          console.error("Error deleting promo:",id, e);
        });
        this.$store.commit("completeLoading");
      })
    },
    validate() {
      this.errorMessage = ""
      if (this.$refs.addPromoForm.validate()) {
        console.log("Validation passed");
        this.addPromo();
      } else {
        this.errorMessage = "Certain entries are invalid or required"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
