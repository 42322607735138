var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.genericFormItemData.name)?_c('label',{staticClass:"label"},[_vm._v(_vm._s(_vm.genericFormItemData.name)+" "),(_vm.genericFormItemData.required)?_c('span',[_vm._v(" (Required) ")]):_vm._e(),(_vm.genericFormItemData.description)?_c('span',{staticClass:"description"},[_vm._v(" - "+_vm._s(_vm.genericFormItemData.description))]):_vm._e()]):_vm._e(),_c('tinymce-editor',{attrs:{"model-events":"change blur","api-key":"rgwuelv76ygmvst2r951kalhen9s0yy7irpy5we625xig9ca","init":{
            selector: 'textarea',
            plugins: 'textpattern link lists table image',
            height: '500px',
            menubar: false,
            forced_root_blocks: false,
            force_br_newlines: true,
            convert_newlines_to_brs: true,
            toolbar: 'undo redo | styleselect | bold italic underline| blockquote table | bullist numlist| link image customInsertButton | \ code',
            plugins: 'table link paste wordcount hr lists textpattern code',
            extended_valid_elements: 'script[language|type|src]',
            textpattern_patterns: [
                { start: '*', end: '*', format: 'italic' },
                { start: '**', end: '**', format: 'bold' },
                { start: '#', format: 'h1' },
                { start: '##', format: 'h2' },
                { start: '###', format: 'h3' },
                { start: '1. ', cmd: 'InsertOrderedList' },
                { start: '* ', cmd: 'InsertUnorderedList' },
                { start: '- ', cmd: 'InsertUnorderedList' },
                { start: '\n\n', format: 'p' }
            ],
            setup: _vm.handleTinyMce,
        }},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }