<template>
  <section class="page-management main-body">

    <div class="pages">
      <MiniHeader :heading="'Pages'" :buttonText="'Add Page'" :hasButton="true" @buttonClicked="addPage()"/>

      <div class="moving-page" v-if="storedPage">
        <p class="moving-page__label">Moving:</p>
        <p class="moving-page__title">{{ storedPage[0].postTitle }}

          <span v-if="storedPage[0].children.length" class="moving-page__child-count">{{ storedPage[0].children.length }} direct subpages</span>
        </p>

        <v-btn
          class="moving-page__cancel"
          color="#fff"
          outline
          flat
          @click="cancelMove()"
        >
          Cancel move
        </v-btn>
      </div>

      <transition-group name="list-complete" tag="ol">

        <li v-for="(level1Item, level1ItemIndex) in pagesList" :key="level1Item.postID" class="page-item">
          <div class="page-item__inner">
            <input v-if="!storedPage" v-model="selectedPage" @change="pageSelectedForMove();" type="radio" name="menu-selector" class="page-item__selector" :value="level1ItemIndex">

            <p class="page-item__status" v-if="level1Item.postStatus !== 'Published'" :class="createStatusClass(level1Item.postStatus)">{{ level1Item.postStatus }}</p>
            <p class="page-item__status status-homepage" v-if="level1Item.postType === 'homepage'">Homepage</p>
            <p class="page-item__status status-external" v-if="level1Item.postType === 'external'">External</p>
            <p class="page-item__status status-external" v-if="level1Item.postType === 'internalLink'">Internal Link</p>
            <p class="page-item__title">{{ level1Item.postTitle }}</p>
            <p v-if="level1Item.postType === 'homepage' || level1Item.postType === 'external' || level1Item.postType === 'internalLink'" class="page-item__template"></p>
            <p v-else class="page-item__template">({{ level1Item.pageTemplate }})</p>

            <template v-if="level1Item.children.length">
              <button @click="closeChildMenu(level1Item.postID)" v-if="checkMenuVisibility(level1Item.postID)" class="page-item__toggle-children">
                <v-icon>expand_less</v-icon> hide {{ level1Item.children.length }} pages
              </button>
              <button @click="expandChildMenu(level1Item.postID)" v-if="!checkMenuVisibility(level1Item.postID)" class="page-item__toggle-children">
                <v-icon>expand_more</v-icon> show {{ level1Item.children.length }} pages
              </button>
            </template>

            <div class="page-item__actions" v-if="!storedPage">

              <a v-if="level1Item.postType === 'homepage'" :href="`${siteUrl}`" target="_blank" class="page-item__slug">View homepage</a>

              <a v-else-if="level1Item.postType === 'external'" :href="`${level1Item.externalUrl}`" target="_blank" class="page-item__slug">{{ level1Item.externalUrl }}</a>

              <a v-else-if="level1Item.postType === 'internalLink'" :href="`${level1Item.linkSlug}`" target="_blank" class="page-item__slug">{{ level1Item.linkSlug }}</a>

              <a v-else-if="level1Item.postStatus === 'Published' || level1Item.postStatus === 'Hidden'" :href="`${siteUrl}/${level1Item.fullPath}`" target="_blank" class="page-item__slug">/{{ level1Item.fullPath }}</a>

              <button class="page-item__edit" type="button" @click="editItem(level1Item.postID)">
                <v-icon>edit</v-icon>
                Edit
              </button>
            </div>

            <div class="page-item__move-actions" v-if="storedPage">
              <button class="page-item__move-button" type="button" @click="movePage('before', level1ItemIndex)">Move before</button>
              <button class="page-item__move-button" type="button" @click="movePage('after', level1ItemIndex)">Move after</button>
              <button class="page-item__move-button" type="button" @click="movePage('into', level1ItemIndex)">Move into</button>
            </div>

            <button class="page-item__copy" type="button" @click="duplicateItem(level1Item.postID)">
              <v-icon>file_copy</v-icon>
            </button>

            <button v-if="level1Item.children.length === 0 && !storedPage" class="page-item__delete" type="button" @click="deleteItem(level1Item.postID)">
              <v-icon small>delete</v-icon>
            </button>
          </div>

          <ol class="page-item__children page-item__children--level-1" v-if="level1Item.children.length" v-show="checkMenuVisibility(level1Item.postID)">
            <li v-for="(level2Item, level2ItemIndex) in level1Item.children" :key="level2Item.postID" class="page-item">
              <div class="page-item__inner">
                <input v-if="!storedPage" v-model="selectedPage" @change="pageSelectedForMove();" type="radio" name="menu-selector" class="page-item__selector" :value="[level1ItemIndex, level2ItemIndex]">
                <p class="page-item__status status-external" v-if="level2Item.postType === 'external'">External</p>
                <p class="page-item__status status-external" v-if="level2Item.postType === 'internalLink'">Internal Link</p>
                <p class="page-item__status" v-if="level2Item.postStatus !== 'Published'" :class="createStatusClass(level2Item.postStatus)">{{ level2Item.postStatus }}</p>
                <p class="page-item__title">{{ level2Item.postTitle }}</p>
                <p v-if="level2Item.postType === 'external' || level2Item.postType === 'internalLink'" class="page-item__template"></p>
                <p v-else class="page-item__template">({{ level2Item.pageTemplate }})</p>

                <template v-if="level2Item.children.length">
                  <button @click="closeChildMenu(level2Item.postID)" v-if="checkMenuVisibility(level2Item.postID)" class="page-item__toggle-children">
                    <v-icon>expand_less</v-icon> hide {{ level2Item.children.length }} pages
                  </button>
                  <button @click="expandChildMenu(level2Item.postID)" v-if="!checkMenuVisibility(level2Item.postID)" class="page-item__toggle-children">
                    <v-icon>expand_more</v-icon> show {{ level2Item.children.length }} pages
                  </button>
                </template>

                <div class="page-item__actions" v-if="!storedPage">
                  <a v-if="level2Item.postType === 'external'" :href="`${level2Item.externalUrl}`" target="_blank" class="page-item__slug">{{ level2Item.externalUrl }}</a>

                   <a v-else-if="level2Item.postType === 'internalLink'" :href="`${level2Item.linkSlug}`" target="_blank" class="page-item__slug">Links to: {{ level2Item.linkSlug }}</a>

                  <a v-else-if="level2Item.postStatus === 'Published' || level2Item.postStatus === 'Hidden'" :href="`${siteUrl}/${level2Item.fullPath}`" target="_blank" class="page-item__slug">/{{ level2Item.fullPath }}</a>

                  <button class="page-item__edit" type="button" @click="editItem(level2Item.postID)">
                    <v-icon>edit</v-icon>
                    Edit
                  </button>
                </div>

                <div class="page-item__move-actions" v-if="storedPage">
                  <button class="page-item__move-button" type="button" @click="movePage('before', level1ItemIndex, level2ItemIndex)">Move before</button>
                  <button class="page-item__move-button" type="button" @click="movePage('after', level1ItemIndex, level2ItemIndex)">Move after</button>
                  <button class="page-item__move-button" type="button" @click="movePage('into', level1ItemIndex, level2ItemIndex)">Move into</button>
                </div>

                <button class="page-item__copy" type="button" @click="duplicateItem(level2Item.postID)">
                  <v-icon>file_copy</v-icon>
                </button>

                <button v-if="level2Item.children.length === 0 && !storedPage" class="page-item__delete" type="button" @click="deleteItem(level2Item.postID)">
                  <v-icon small>delete</v-icon>
                </button>

              </div>

              <ol class="page-item__children" v-if="level2Item.children.length" v-show="checkMenuVisibility(level2Item.postID)">
                <li v-for="(level3Item, level3ItemIndex) in level2Item.children" :key="level3Item.postID" class="page-item">
                  <div class="page-item__inner">
                    <input v-if="!storedPage" v-model="selectedPage" @change="pageSelectedForMove();" type="radio" name="menu-selector" class="page-item__selector" :value="[level1ItemIndex, level2ItemIndex, level3ItemIndex]">

                    <p class="page-item__status status-external" v-if="level3Item.postType === 'external'">External</p>
                    <p class="page-item__status status-external" v-if="level3Item.postType === 'internalLink'">Internal Link</p>
                    <p class="page-item__status" v-if="level3Item.postStatus !== 'Published'" :class="createStatusClass(level3Item.postStatus)">{{ level3Item.postStatus }}</p>
                    <p class="page-item__title">{{ level3Item.postTitle }}</p>
                    <p v-if="level3Item.postType === 'external' || level3Item.postType === 'internalLink'" class="page-item__template"></p>
                    <p class="page-item__template">({{ level3Item.pageTemplate }})</p>

                    <template v-if="level3Item.children.length">
                      <button @click="closeChildMenu(level3Item.postID)" v-if="checkMenuVisibility(level3Item.postID)" class="page-item__toggle-children">
                        <v-icon>expand_less</v-icon> hide {{ level3Item.children.length }} pages
                      </button>
                      <button @click="expandChildMenu(level3Item.postID)" v-if="!checkMenuVisibility(level3Item.postID)" class="page-item__toggle-children">
                        <v-icon>expand_more</v-icon> show {{ level3Item.children.length }} pages
                      </button>
                    </template>

                    <div class="page-item__actions" v-if="!storedPage" >
                      <a v-if="level3Item.postType === 'external'" :href="`${level3Item.externalUrl}`" target="_blank" class="page-item__slug">{{ level3Item.externalUrl }}</a>

                       <a v-else-if="level3Item.postType === 'internalLink'" :href="`${level3Item.linkSlug}`" target="_blank" class="page-item__slug">Links to: {{ level3Item.linkSlug }}</a>

                      <a v-else-if="level3Item.postStatus === 'Published' || level3Item.postStatus === 'Hidden'" :href="`${siteUrl}/${level3Item.fullPath}`" target="_blank" class="page-item__slug">/{{ level3Item.fullPath }}</a>

                      <button class="page-item__edit" type="button" @click="editItem(level3Item.postID)">
                        <v-icon>edit</v-icon>
                        Edit
                      </button>
                    </div>

                    <div class="page-item__move-actions" v-if="storedPage">
                      <button class="page-item__move-button" type="button" @click="movePage('before', level1ItemIndex, level2ItemIndex, level3ItemIndex)">Move before</button>
                      <button class="page-item__move-button" type="button" @click="movePage('after', level1ItemIndex, level2ItemIndex, level3ItemIndex)">Move after</button>
                      <button class="page-item__move-button" type="button" @click="movePage('into', level1ItemIndex, level2ItemIndex, level3ItemIndex)">Move into</button>
                    </div>

                    <button class="page-item__copy" type="button" @click="duplicateItem(level3Item.postID)">
                      <v-icon>file_copy</v-icon>
                    </button>

                    <button v-if="level3Item.children.length === 0 && !storedPage" class="page-item__delete" type="button" @click="deleteItem(level3Item.postID)">
                      <v-icon small>delete</v-icon>
                    </button>
                  </div>

                  <ol class="page-item__children" v-if="level3Item.children.length" v-show="checkMenuVisibility(level3Item.postID)">
                    <li v-for="(level4Item, level4ItemIndex) in level3Item.children" :key="level4Item.postID" class="page-item">
                      <div class="page-item__inner">
                        <input v-if="!storedPage" v-model="selectedPage" @change="pageSelectedForMove();" type="radio" name="menu-selector" class="page-item__selector" :value="[level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex]">

                        <p class="page-item__status status-external" v-if="level4Item.postType === 'external'">External</p>
                        <p class="page-item__status status-external" v-if="level4Item.postType === 'internalLink'">Internal Link</p>
                        <p class="page-item__status" v-if="level4Item.postStatus !== 'Published'" :class="createStatusClass(level4Item.postStatus)">{{ level4Item.postStatus }}</p>
                        <p class="page-item__title">{{ level4Item.postTitle }}</p>
                        <p v-if="level4Item.postType === 'external' || level4Item.postType === 'internalLink'" class="page-item__template"></p>
                        <p v-else class="page-item__template">({{ level4Item.pageTemplate }})</p>

                        <template v-if="level4Item.children.length">
                          <button @click="closeChildMenu(level4Item.postID)" v-if="checkMenuVisibility(level4Item.postID)" class="page-item__toggle-children">
                            <v-icon>expand_less</v-icon> hide {{ level4Item.children.length }} pages
                          </button>
                          <button @click="expandChildMenu(level4Item.postID)" v-if="!checkMenuVisibility(level4Item.postID)" class="page-item__toggle-children">
                            <v-icon>expand_more</v-icon> show {{ level4Item.children.length }} pages
                          </button>
                        </template>

                        <div class="page-item__actions" v-if="!storedPage">
                          <a v-if="level4Item.postType === 'external'" :href="`${level4Item.externalUrl}`" target="_blank" class="page-item__slug">{{ level4Item.externalUrl }}</a>

                          <a v-else-if="level4Item.postType === 'internalLink'" :href="`${level4Item.linkSlug}`" target="_blank" class="page-item__slug">Links to: {{ level4Item.linkSlug }}</a>

                          <a v-else-if="level4Item.postStatus === 'Published' || level4Item.postStatus === 'Hidden'" :href="`${siteUrl}/${level4Item.fullPath}`" target="_blank" class="page-item__slug">/{{ level4Item.fullPath }}</a>

                          <button class="page-item__edit" type="button" @click="editItem(level4Item.postID)">
                            <v-icon>edit</v-icon>
                            Edit
                          </button>
                        </div>

                        <div class="page-item__move-actions" v-if="storedPage">
                          <button class="page-item__move-button" type="button" @click="movePage('before', level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex)">Move before</button>
                          <button class="page-item__move-button" type="button" @click="movePage('after', level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex)">Move after</button>
                          <button class="page-item__move-button" type="button" @click="movePage('into', level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex)">Move into</button>
                        </div>

                        <button class="page-item__copy" type="button" @click="duplicateItem(level4Item.postID)">
                          <v-icon>file_copy</v-icon>
                        </button>

                        <button v-if="level4Item.children.length === 0 && !storedPage" class="page-item__delete" type="button" @click="deleteItem(level4Item.postID)">
                          <v-icon small>delete</v-icon>
                        </button>
                      </div>

                      <ol class="page-item__children" v-if="level4Item.children.length" v-show="checkMenuVisibility(level4Item.postID)">
                        <li v-for="(level5Item, level5ItemIndex) in level4Item.children" :key="level5Item.postID" class="page-item">
                          <div class="page-item__inner">
                            <input v-if="!storedPage" v-model="selectedPage" @change="pageSelectedForMove();" type="radio" name="menu-selector" class="page-item__selector" :value="[level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex, level5ItemIndex]">

                            <p class="page-item__status status-external" v-if="level5Item.postType === 'external'">External</p>
                            <p class="page-item__status status-external" v-if="level5Item.postType === 'internalLink'">Internal Link</p>
                            <p class="page-item__status" v-if="level5Item.postStatus !== 'Published'" :class="createStatusClass(level5Item.postStatus)">{{ level5Item.postStatus }}</p>
                            <p class="page-item__title">{{ level5Item.postTitle }}</p>
                            <p v-if="level5Item.postType === 'external' || level5Item.postType === 'internalLink'" class="page-item__template"></p>
                            <p v-else class="page-item__template">({{ level5Item.pageTemplate }})</p>

                            <template v-if="level5Item.children.length">
                              <button @click="closeChildMenu(level5Item.postID)" v-if="checkMenuVisibility(level5Item.postID)" class="page-item__toggle-children">
                                <v-icon>expand_less</v-icon> hide {{ level1Item.children.length }} pages
                              </button>
                              <button @click="expandChildMenu(level5Item.postID)" v-if="!checkMenuVisibility(level5Item.postID)" class="page-item__toggle-children">
                                <v-icon>expand_more</v-icon> show {{ level5Item.children.length }} pages
                              </button>
                            </template>

                            <div class="page-item__actions" v-if="!storedPage">
                              <a v-if="level5Item.postType === 'external'" :href="`${level5Item.externalUrl}`" target="_blank" class="page-item__slug">{{ level5Item.externalUrl }}</a>

                              <a v-else-if="level5Item.postType === 'internalLink'" :href="`${level5Item.linkSlug}`" target="_blank" class="page-item__slug">Links to: {{ level5Item.linkSlug }}</a>

                              <a v-if="level5Item.postStatus === 'Published' || level5Item.postStatus === 'Hidden'" :href="`${siteUrl}/${level5Item.fullPath}`" target="_blank" class="page-item__slug">/{{ level5Item.fullPath }}</a>

                              <button class="page-item__edit" type="button" @click="editItem(level5Item.postID)">
                                <v-icon>edit</v-icon>
                                Edit
                              </button>
                            </div>

                            <div class="page-item__move-actions" v-if="storedPage">
                              <button class="page-item__move-button" type="button" @click="movePage('before', level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex, level5ItemIndex)">Move before</button>
                              <button class="page-item__move-button" type="button" @click="movePage('after', level1ItemIndex, level2ItemIndex, level3ItemIndex, level4ItemIndex, level5ItemIndex)">Move after</button>
                            </div>

                            <button class="page-item__copy" type="button" @click="duplicateItem(level5Item.postID)">
                              <v-icon>file_copy</v-icon>
                            </button>

                            <button v-if="level5Item.children.length === 0 && !storedPage" class="page-item__delete" type="button" @click="deleteItem(level5Item.postID)">
                              <v-icon small>delete</v-icon>
                            </button>
                          </div>
                        </li>
                      </ol>
                    </li>
                  </ol>

                </li>
              </ol>

            </li>
          </ol>

        </li>
      </transition-group>
    </div>

    <!-- <div class="beta-notice">
      <p><strong>Legacy:</strong> We are developing a new page management system to make it easier to move pages around. This is still in development so has some missing features, but you can toggle between the two at the moment.</p>
      <v-btn
        color="accent"
        outline
        flat
        @click="togglePageManagement()"
      >
        Back to Legacy Page Management System
      </v-btn>
    </div> -->

    <StickyFooter v-if="!storedPage" :errorMessage="errorMessage" :status="status" :buttonText="'Save Pages'" @buttonClicked="savePages()" />

  </section>
</template>

<script>
import axios from "axios"
import MiniHeader from "@/components/Library/MiniHeader"
import StickyFooter from "@/components/Library/StickyFooter"

export default {
  name: "PageManagementNewMove",

  components: {
    MiniHeader,
    StickyFooter
  },

  data: () => ({
    api: `${process.env.VUE_APP_BASEURL}/pages`,
    status: "saved",
    errorMessage: "",
    hiddenMenus: [],
    visibleMenus: [],
    pagesList: [],

    selectedPage: null,
    storedPage: null,

    pagesListHistory: [],

    siteUrl: `https://${process.env.VUE_APP_SITE_DOMAIN}`
  }),

  mounted() {
    console.log("Page Management mounted");
    this.fetchPages();
  },

  methods: {
    togglePageManagement() {
      this.$router.push("/pages/legacy")
    },

    cancelMove() {
      this.pagesList = this.pagesListHistory.pop()

      this.selectedPage = null
      this.storedPage = null
    },

    createStatusClass(status) {
      return `status-${status.toLowerCase().trim()}`;
    },

    pageSelectedForMove() {
      if (this.storedPage) {
        this.pagesList = this.pagesListHistory.pop()
      }

      console.log("pageSelectedForMove", this.selectedPage)

      // Saved for cancel
      this.pagesListHistory.push(JSON.parse(JSON.stringify(this.pagesList)));

      console.log("this.selectedPage", this.selectedPage)
      const selectedPage = this.selectedPage.toString()
      const pageLocationArray = selectedPage.split(",");

      console.log("pageLocationArray", pageLocationArray)

      // Store
      if (pageLocationArray.length === 1) {

        console.log("pageLocationArray length 1")        
        this.storedPage = this.pagesList.splice(pageLocationArray[0], 1)

      } else if (pageLocationArray.length === 2) {

        console.log("pageLocationArray length 2")
        this.storedPage = this.pagesList[pageLocationArray[0]].children.splice(pageLocationArray[1], 1)

      } else if (pageLocationArray.length === 3) {

        console.log("pageLocationArray length 3")
        this.storedPage = this.pagesList[pageLocationArray[0]].children[pageLocationArray[1]].children.splice(pageLocationArray[2], 1)

      } else if (pageLocationArray.length === 4) {

        console.log("pageLocationArray length 4")
        this.storedPage = this.pagesList[pageLocationArray[0]].children[pageLocationArray[1]].children[pageLocationArray[2]].children.splice(pageLocationArray[3], 1)

      } else if (pageLocationArray.length === 5) {

        console.log("pageLocationArray length 5")
        this.storedPage = this.pagesList[pageLocationArray[0]].children[pageLocationArray[1]].children[pageLocationArray[2]].children[pageLocationArray[3]].children.splice(pageLocationArray[4], 1)

      }

      this.selectedPage = null

      console.log("pagesList after removal", this.pagesList)

    },

    movePage(location, level1Index, level2Index, level3Index, level4Index, level5Index) {

      if (this.storedPage[0].children.length > 0) {
        if (confirm("The page you are moving has child pages. Some of these may be inaccessible if they are further than 5 levels deep. Do you want to continue?")) {
          console.log("Continue moving page")
        } else {
          return
        }
      }

      this.outOfSync()

      console.log("location", location, level1Index, level2Index, level3Index, level4Index, level5Index)

      console.log("movePage", this.pagesList)

      // Save this to duplicate page state whilst modifying array

      const tempPageStructure = JSON.parse(JSON.stringify(this.pagesList));

      // Warn if page has children and is being moved to somewhere they will not be accessible

      // Insert
      if (level5Index != undefined) {
        console.log("level5 move", level5Index)

        let insertionPoint = level5Index

        console.log("Test", tempPageStructure[level1Index].children[level2Index].children[level3Index].children[level4Index].children[insertionPoint])

        if (location === "before") {
          tempPageStructure[level1Index].children[level2Index].children[level3Index].children[level4Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "after") {
          insertionPoint = insertionPoint + 1
          tempPageStructure[level1Index].children[level2Index].children[level3Index].children[level4Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "into") {
          tempPageStructure[level1Index].children[level2Index].children[level3Index].children[level4Index].children[insertionPoint].children.splice(0, 0, this.storedPage[0])
        }
      }
      
      else if (level4Index != undefined) {
        console.log("level4 move", level4Index)

        let insertionPoint = level4Index

        console.log("Test", tempPageStructure[level1Index].children[level2Index].children[level3Index].children[insertionPoint])

        if (location === "before") {
          tempPageStructure[level1Index].children[level2Index].children[level3Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "after") {
          insertionPoint = insertionPoint + 1
          tempPageStructure[level1Index].children[level2Index].children[level3Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "into") {
          tempPageStructure[level1Index].children[level2Index].children[level3Index].children[insertionPoint].children.splice(0, 0, this.storedPage[0])
        }
      }

      else if (level3Index != undefined) {
        console.log("level3 move", level3Index)

        let insertionPoint = level3Index

        console.log("Test", tempPageStructure[level1Index].children[level2Index].children[insertionPoint])

        if (location === "before") {
          tempPageStructure[level1Index].children[level2Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "after") {
          insertionPoint = insertionPoint + 1
          tempPageStructure[level1Index].children[level2Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "into") {
          tempPageStructure[level1Index].children[level2Index].children[insertionPoint].children.splice(0, 0, this.storedPage[0])
        }
      }

      else if (level2Index != undefined) {
        console.log("level2 move", level2Index)

        let insertionPoint = level2Index

        if (location === "before") {
          tempPageStructure[level1Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "after") {
          insertionPoint = insertionPoint + 1
          tempPageStructure[level1Index].children.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "into") {
          tempPageStructure[level1Index].children[insertionPoint].children.splice(0, 0, this.storedPage[0])
        }
      }

      else if (level1Index != undefined) {
        console.log("level1 move", level1Index)

        let insertionPoint = level1Index

        if (location === "before") {
          tempPageStructure.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "after") {
          insertionPoint = insertionPoint + 1
          tempPageStructure.splice(insertionPoint, 0, this.storedPage[0])
        } else if (location === "into") {
          tempPageStructure[insertionPoint].children.splice(0, 0, this.storedPage[0])
        }
      }
      
      // Replace page state with temp page state
      this.pagesList = tempPageStructure

      this.selectedPage = null
      this.storedPage = null
    },

    fetchPages(doSave = false) {
      this.$store.commit("startLoading");
      this.errorMessage = "";

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
        .get(this.api, { "headers": { "Authorization": jwt }})
        .then(response => {
          console.log("Fetch Pages: ", response);
          this.pagesList = response.data.body;

          if (doSave) {
            this.savePages()
          } else {
            this.status = "saved";
            this.$store.commit("completeLoading");
          }
        })
        .catch(e => {
          console.error("Problem retrieving pages", e);
          this.errorMessage = "Problem retrieving pages";
          this.$store.commit("completeLoading");
        });
      })
    },

    savePages() {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/updateorder`,
          headers: { "Authorization": jwt },
          data: this.pagesList
        })
        .then(response => {
          console.log(response);
          this.status = "saved";

          this.$store.commit("completeLoading");
        })
        .catch(e => {
          console.error("Problem saving menu", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem saving pages";

          this.$store.commit("completeLoading");
        });
      })
    },

    addPage() {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "POST",
          url: `${this.api}/add`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log("Add page response:", response);

          this.fetchPages(true);
        })
        .catch(e => {
          console.error("Problem adding page", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem adding page";

          this.$store.commit("completeLoading");
        });
      })
    },

    duplicateItem(postID) {
      this.status = "saving";
      this.$store.commit("startLoading");

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/duplicate/${postID}`,
          headers:  { "Authorization": jwt }
        })
        .then(response => {
          console.log("Duplicate item response:", response);
          this.fetchPages();
        })
        .catch(e => {
          console.error("Problem duplicating item", e);
          this.status = "needsSaving";
          this.errorMessage = "Problem duplicating item";

          this.$store.commit("completeLoading");
        });
      })
    },

    editItem(postID) {
      if (this.status === "needsSaving") {
        if (confirm("Your page structure has changes which will be lost if you leave this page. Are you sure you want to leave?")) {
          this.$router.push({ path: `/pages/${postID}` })
        }
      } else {
        this.$router.push({ path: `/pages/${postID}` })
      }
    },

    deleteItem(postID) {
      if (this.status === "needsSaving") {
        alert("You are unable to delete until the page structure is saved")
      } else {

        if (confirm("Are you sure you want to delete this page?")) {
          this.status = "saving";
          this.$getCurrentUserJwToken().subscribe((jwt) => {
            axios.delete(`${process.env.VUE_APP_BASEURL}/pages/${postID}`, { "headers": { "Authorization": jwt }})
            .then(() => {
              console.log("Deleting page: ", postID);
              this.fetchPages();
              this.status = "saved";

            }).catch(e => {
              console.error("Problem saving menu: ", e);
              this.status = "needsSaving";
              this.errorMessage = "Failed to delete page";

              this.$store.commit("completeLoading");
            });
          })
        }

      }
    },

    outOfSync() {
      this.status = "needsSaving";
    },

    expandChildMenu(itemID) {
      // this.hiddenMenus.splice(this.hiddenMenus.indexOf(itemID), 1);

      this.visibleMenus.push(itemID);
    },

    closeChildMenu(itemID) {
      // this.hiddenMenus.push(itemID);

      this.visibleMenus.splice(this.visibleMenus.indexOf(itemID), 1);
    },

    checkMenuVisibility(itemID) {
      // if (!this.hiddenMenus.includes(itemID)) {
      //   return true
      // } else {
      //   return false
      // }
      
      if (this.visibleMenus.includes(itemID)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/settings';

  .moving-page {
    position: fixed;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    z-index: 10;
    margin: 0 0 16px;
    padding: 16px;
    color: #fff;
    background-color: #516173;
    border-radius: 6px;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.3);

    &__label {
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
    }

    &__title {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }

    &__cancel {
      position: absolute;
      top: 50%;
      right: 16px;
      margin: 0;
      z-index: 1;
      transform: translateY(-50%);
    }

    &__child-count {
      font-size: 14px;
      font-weight: 300;
    }
  }

  .page-item {
    transition: all 0.4s;

    &__inner {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 16px;
      padding: 8px 16px;
      height: 64px;
      background-color: #fff;
      border-radius: 6px;
      border: 1px solid #cdcdcd;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        width: 40px;
      }

      &:hover {
        .page-item__move-actions {
          opacity: 1;
        }
        .page-item__delete {
          opacity: 1;
        }
        .page-item__copy {
          opacity: 1;
        }
      }
    }

    &__slug {
      margin: 0;
      padding: 0;
      font-size: 10px;
      position: absolute;
      bottom: 3px;
      left: 42px;
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }

    &__toggle-children {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      color: #7b6b80;
      font-size: 10px;
      outline: none;

      /deep/ .v-icon {
        color: #7b6b80 !important;
        font-size: 20px !important;
      }
    }

    &__status {
      padding: 3px 12px;
      margin: 0 8px 0 0;
      font-size: 10px;
      color: #fff;
      background-color: #E6E8F1;
      border-radius: 4px;

      &.status-draft {
        background-color: color(draftHighlight);
      }

      &.status-hidden {
        background-color: color(hiddenHighlight);
      }

      &.status-new {
        background-color: color(newHighlight);
      }

      &.status-homepage {
        color: #111;
        background-color: palegoldenrod;
      }

      &.status-external {
        color: #111;
        background-color: peachpuff;
      }
    }

    &__selector {
      margin: 0 16px 0 0;
    }

    &__title {
      padding: 0;
      margin: 0;
      font-weight: 400;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
    }

    &__edit {
      margin: 0;
      padding: 6px 8px;
      color: #7b6b80;
      font-size: 14px;
      font-weight: 500;
      background-color: #f1f4f8;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-out;

      /deep/ .v-icon {
        margin-right: 2px;
        color: #7b6b80;
        font-size: 16px;
      }

      &:hover {
        color: #fff;
        background-color: #67247c;

        /deep/ .v-icon {
          color: #fff;
        }
      }
    }

    &__copy {
      position: absolute;
      top: 12px;
      left: -23px;
      padding: 3px;
      background-color: #ccc;
      border-radius: 4px 0 0 4px;
      opacity: 0;
      outline: none;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;

      i {
        font-size: 16px;
      }
    }

    &__move-actions {
      opacity: 0.6;
      transition: all 0.2s ease-out;
    }

    &__move-button {
      margin-left: 8px;
      padding: 4px 8px;
      color: #7b6b80;
      font-size: 12px;
      background-color: #f1f4f8;
      border-radius: 4px;
      transition: all 0.2s ease-out;

      &:hover {
        color: #fff;
        background-color: #516173;
      }
    }

    &__template {
      margin: 0 auto 0 8px;
      padding: 0;
      color: #67247c;
      font-style: italic;
      font-weight: 500;
    }

    &__delete {
      position: absolute;
      top: 12px;
      right: -23px;
      padding: 3px;
      background-color: #ccc;
      border-radius: 0 4px 4px 0;
      opacity: 0;
      outline: none;
      transition: opacity 0.3s ease-in-out;
      z-index: 1;
    }

    &__children {
      position: relative;
      margin: 16px 0 0 45px;

      &:before {
        content: '';
        position: absolute;
        top: -16px;
        left: -23px;
        bottom: 30px;
        width: 133px;
        border-left: 2px dotted #cdcdcd;
      }

      &--level-1:before {
        border-bottom: 2px dotted #cdcdcd;
      }

      .page-item__inner:before {
        content: '';
        position: absolute;
        top: 31px;
        left: -23px;
        right: 100%;
        height: 2px;
        border-bottom: 2px dotted #cdcdcd;
      }
    }
  }

  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }

  .child-menu {
    margin: 0 0 0 80px;
    padding: 0;
    transition: padding 0.2s;
  }

  .status {
    margin: 0 10px 0 0;
    font-size: 13px;
    opacity: 0.5;
  }
</style>
