import { useGenericData } from "../../composables/useGenericData";
import { IGenericSetup } from "../../types/genericData";

export const blogEntryData: IGenericSetup = {
    text: {
        title: "Blog Entries",
        noItems: "No Blog Entries were found",
        newItem: "New Blog Entry",
        postModalTitle: "New Blog Entry"
    },
    api: {
        type: "live-blog", // Our APIs require this property
        // @ts-ignore
        base: `${process.env.VUE_APP_BLOGAPI}/entries`,
        create: "/",
        getAll: "/",
        getSingle: "/",
        edit: "/",
        delete: "/",
        dynamicBase: (id: string) => {
            return `${process.env.VUE_APP_BLOGAPI}/${id}/entries`
        }
    },
    create: {
        enabled: true,
        type: "modal",
        schema: [
            {
                name: "Title",
                description: "The title of the blog entry",
                type: "text",
                key: "title",
                value: "",
                placeholder: "Title"
            },
            {
                name: "Content",
                description: "The main body of the blog entry",
                type: "richText",
                key: "content",
                value: "",
                required: true,
            },
            {
                name: "Image",
                description: "Image used alongside the blog entry",
                type: "image",
                key: "image",
                value: "",
            },
            {
                name: "Breaking",
                description: "Highlights important stories",
                type: "switch",
                key: "breaking",
                value: false,
            },
            {
                name: "Date and Time",
                description: "This is used to determine the order in which entries will display on the website",
                type: "date",
                key: "dateTime",
                value: "",
                required: true,
            },
            {
                name: "Custom Date and time",
                description: "If this is filled, it shows the date and time in this format instead",
                type: "text",
                key: "customDateTime",
                value: "",
            },
            {
                name: "Icon",
                description: "Displays an icon alongside the post",
                type: "icon",
                key: "icon",
                value: "",
            },
            {
                name: "Status",
                description: "Defines whether the entry is displayed",
                type: "select",
                key: "status",
                value: "Draft",
                options: [
                    {
                        name: "Published",
                        value: "Published"
                    },
                    {
                        name: "Draft",
                        value: "Draft"
                    }
                ]
            },
        ]
    },
    getAll: {
        enabled: true,
        map: {
            name: "title",
            date: "dateTime",
            label: "status",
            activeLabel: "Published",
        }
    },
    edit: {
        enabled: true,
        type: "modal",
        schema: [
            {
                name: "Title",
                description: "The title of the blog entry",
                type: "text",
                key: "title",
                value: "",
                placeholder: "Title",
            },
            {
                name: "Content (required)",
                description: "The main body of the blog entry",
                type: "richText",
                key: "content",
                value: "",
                required: true,
            },
            {
                name: "Image",
                description: "Image used alongside the blog entry",
                type: "image",
                key: "image",
                value: "",
            },
            {
                name: "Breaking",
                description: "Highlights important stories",
                type: "switch",
                key: "breaking",
                value: false,
            },
            {
                name: "Date and time (required)",
                description: "This is used to determine the order in which entries will display on the website",
                type: "date",
                key: "dateTime",
                value: "",
                required: true,
            },
            {
                name: "Custom Date and time",
                description: "If this is filled, it shows the date and time in this format instead",
                type: "text",
                key: "customDateTime",
                value: "",
            },
            {
                name: "Icon",
                description: "Displays an icon alongside the post",
                type: "icon",
                key: "icon",
                value: "",
            },
            {
                name: "Status",
                description: "Defines whether the entry is displayed",
                type: "select",
                key: "status",
                value: "Draft",
                options: [
                    {
                        name: "Published",
                        value: "Published"
                    },
                    {
                        name: "Draft",
                        value: "Draft"
                    }
                ]
            },
        ]
    },
    useGenericData: () => useGenericData(blogEntryData.api)
};
