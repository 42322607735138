<template>
    <form
        class="form"
        :class="{ 'form--column': includeColumns }"
    >
        <Component
            v-for="item in genericFormData"
            class="form-item"
            :key="item.key"
            :is="formItemsMap[item.type]"
            :genericFormItemData="item"
            @updated="$emit('updated', { value: $event, key: item.key })"
        />
    </form>
</template>

<script>
import { defineComponent, } from "vue";
import GenericTextInput from "./GenericTextInput.vue";
import GenericRichTextInput from "./GenericRichTextInput.vue";
import GenericSelectInput from "./GenericSelectInput.vue";
import GenericDateInput from "./GenericDateInput.vue";
import GenericSwitchInput from "./GenericSwitchInput.vue";
import GenericIconInput from "./GenericIconInput.vue";
import GenericImageInput from "./GenericImageInput.vue";

export default defineComponent({
    name: "GenericForm",

    components: {
        GenericTextInput,
        GenericRichTextInput,
        GenericDateInput,
        GenericSelectInput,
        GenericSwitchInput,
        GenericIconInput,
        GenericImageInput,
    },

    props: {
        genericFormData: {
            type: Array,
            required: true,
        },
        includeColumns: {
            type: Boolean,
            default: false,
        }
    },

    setup() {
        const formItemsMap = {
            text: "GenericTextInput",
            richText: "GenericRichTextInput",
            select: "GenericSelectInput",
            switch: "GenericSwitchInput",
            date: "GenericDateInput",
            icon: "GenericIconInput",
            image: "GenericImageInput",
            none: "",
        }

        return {
            formItemsMap,
        }
    }
})
</script>

<style scoped lang='scss'>
.form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (min-width: 768px) {
        gap: 24px;
    }

    &--column {
        @media (min-width: 768px) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}

.form-item {
    flex: 0 1 calc(50% - 8px);

    @media screen and (min-width: 768px) {
        flex: 0 1 calc(50% - 12px);

    }
}
</style>
